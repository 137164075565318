import React, { useState } from "react";
import axios from "axios";
import Timer from "../../../components/Timer";

import {
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Button,
  Box,
} from "@mui/material";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import {
  adminTrustLevelStyles,
  checkIfUserHasAccess,
  getInheritedEndpointResourceStatusValue,
  userTrustLevelStyles,
} from "../../../../services/Helpers";
import ResourceRequestForm from "../../../components/ResourceRequestForm";

const UrlControlRow = ({
  urlControl,
  tenantName,
  selectedUser,
  fetchUrlControlData,
  computer,
}) => {
  const userData = useUserStore((state) => state.user);

  const [timedUrlsWithoutAccess, setTimedUrlsWithoutAccess] = useState([]);

  const checkIfTimeShouldBeShown = (url) => {
    if (
      url.groupStatus === "DYNAMIC" ||
      (url.inheritedGroupStatus === "DYNAMIC" &&
        url.groupStatus === "INHERITED")
    ) {
      return true;
    } else return false;
  };

  const updateGroupsStatus = async (url, groupStatus) => {
    const timeInSeconds = computer.defaultTime * 60;

    try {
      await axios.put(
        `${NGROK}/api/${tenantName}/computer-user/url-control/timer`,
        {
          userId: selectedUser.id,
          urlId: url.urlId,
          groupStatus,
          selectedTime: timeInSeconds,
          email: userData.email,
          role: userData.role,
        }
      );

      fetchUrlControlData();
    } catch (error) {
      console.error(error, "failed to update groupStatus");
    }
  };

  const deleteUrl = async (urlId) => {
    try {
      const response = await axios.delete(
        `${NGROK}/api/url-control/${urlId}`
      );

      if (response.data) fetchUrlControlData();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <TableRow className="groupRow" key={`${urlControl.name}`}>
        <TableCell
          component="td"
          scope="row"
          className="groupRowName userName"
          title={urlControl.name}
        >
          {urlControl.name}
        </TableCell>
        <TableCell className="privilegeLevelTableCell">
          <Box
            className={
              userData.role === "TENANT_USER"
                ? userTrustLevelStyles(urlControl)
                : adminTrustLevelStyles(urlControl)
            }
          >
            <Select
              disabled={userData.role === "TENANT_USER"}
              sx={{ minWidth: "100px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => updateGroupsStatus(urlControl, e.target.value)}
              value={urlControl.groupStatus}
              size="small"
            >
              <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
              <MenuItem value={"DISABLED"}>DENIED</MenuItem>
              <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
              <MenuItem value={"INHERITED"}>
                INHERITED {getInheritedEndpointResourceStatusValue(urlControl)}
              </MenuItem>
            </Select>
            {checkIfTimeShouldBeShown(urlControl) ? (
              <Timer
                setTimedResourcesWithoutAccess={setTimedUrlsWithoutAccess}
                resourceId={urlControl.urlId}
                seconds={urlControl.remainingTime}
              />
            ) : null}

            {!checkIfUserHasAccess(
              urlControl,
              userData,
              timedUrlsWithoutAccess,
              urlControl.urlId
            ) ? (
              <ResourceRequestForm
                tenantName={tenantName}
                selectedUser={selectedUser}
                resourceId={urlControl.urlId}
                resourceType={"URL"}
                computer={computer}
                resourceName={urlControl.name}
              />
            ) : null}
          </Box>
        </TableCell>
        {userData.role !== "TENANT_USER" ? (
          <TableCell
            component="td"
            scope="row"
            className="groupRowName userName"
            align="center"
            sx={{ width: "50px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => deleteUrl(urlControl.urlId)}
            >
              Delete
            </Button>
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};

export default UrlControlRow;
