import React, { useEffect } from "react";
import axios from "axios";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";

import { AuthProvider } from "./contexts/JWTContext";

import useUserStore from "./services/userStore";

import { myLocalStorage } from "./components/StorageHelper";
import { QueryClient, QueryClientProvider } from "react-query";
import UserService from "./services/UserService";
import {
  checkIfTokenExpired,
  decodeKeycloakToken,
  refreshAccessToken,
  sendTokenToBackend,
} from "./utils/tokenHelpers";
import { clearLocalStorageOnLogout, usersRoutes } from "./services/Helpers";
import { channel, logOut } from "./services/Auth";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const App = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const location = useLocation();

  const content = useRoutes(routes);
  const { user: userData, getUserData: fetchUserData } = useUserStore(
    (state) => state
  );

  const lastVisitedPage = myLocalStorage.getItem("lastVisitedPage");
  const access_token =
    UserService?.getToken() || myLocalStorage.getItem("access_token");

  const realm = myLocalStorage.getItem("realm");

  const logOutDueToTokenExpire = () => {
    console.log("APP component logout");
    const email = myLocalStorage.getItem("email");
    return logOut(email)
      .then(() => {
        clearLocalStorageOnLogout();
        navigate("/signIn");
      })
      .catch((error) => {
        console.error("An error occurred during logout:", error);
      });
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    const allowedPaths = [
      "/",
      "/signUp",
      "/signIn",
      "null",
      "/terms_of_service",
      "/loading",
      "/404"
    ];
    if (!allowedPaths.includes(location.pathname)) {
      myLocalStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (access_token) {
      const { email: userEmail } = decodeKeycloakToken(access_token);
      fetchUserData(userEmail);
    }
  }, [access_token, fetchUserData]);

  useEffect(() => {
    const navigateOnRefresh = async (user) => {
      if (user.role !== "TENANT_USER") {
        navigate(lastVisitedPage ? lastVisitedPage : "/dashboard/overview");
      } else {
        navigate(
          lastVisitedPage && usersRoutes.includes(lastVisitedPage)
            ? lastVisitedPage
            : "/endpoints"
        );
      }
    };

    if (userData?.role && access_token) {
      navigateOnRefresh(userData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (!access_token && realm) UserService.initKeycloak(realm);
  }, [access_token, realm]);

  useEffect(() => {
    const logoutMultipleTabs = () => {
      channel.onmessage = () => {
        navigate("/signIn");
      };
    };
    logoutMultipleTabs();
  }, [navigate]);

  axios.interceptors.request.use(
    (request) => {
      const access_token = myLocalStorage.getItem("access_token");

      if (access_token) {
        request.headers.Authorization = `Bearer ${access_token}`;
      }
      return request;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async (response) => {
      if (response?.status === 401) {
        console.log("You are not authorized");
      }
      return response;
    },
    async (error) => {
      // if (axios.isCancel(error)) {
      //   console.log("Request canceled:", error.message);
      //   return Promise.reject(error);
      // }

      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const access_token = myLocalStorage.getItem("access_token");
        if (!access_token) navigate("/signIn");

        try {
          const email = myLocalStorage.getItem("email");
          const role = myLocalStorage.getItem("role");
          const realm = myLocalStorage.getItem("realm");

          const stored_refresh_token = myLocalStorage.getItem("refresh_token");
          const isRefreshTokenExpired =
            checkIfTokenExpired(stored_refresh_token);

          if (isRefreshTokenExpired) {
            logOutDueToTokenExpire();
            return;
          } else {
            console.log("Refresh Token is active");
          }
          const { access_token, refresh_token } = await refreshAccessToken();
          const { exp } = decodeKeycloakToken(access_token);
          myLocalStorage.setItem("access_token", access_token);
          myLocalStorage.setItem("refresh_token", refresh_token);

          await sendTokenToBackend(realm, email, role, access_token, exp);

          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          return axios(originalRequest);
        } catch (refreshTokenError) {
          console.error(refreshTokenError);
          return Promise.reject(refreshTokenError);
        }
      } else {
        console.error("Error occurred:", error);
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      {userData?.loading ? (
        <p>loading</p>
      ) : (
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | White Swan Security"
            defaultTitle="White Swan Security"
          />
            <QueryClientProvider client={queryClient}>
              <StylesProvider jss={jss}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={createTheme(theme)}>
                      <ThemeProvider theme={createTheme(theme)}>
                        <AuthProvider>{content}</AuthProvider>
                      </ThemeProvider>
                    </MuiThemeProvider>
                  </StyledEngineProvider>
                </LocalizationProvider>
              </StylesProvider>
            </QueryClientProvider>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
