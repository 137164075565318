import axios from "axios";
import qs from "qs";
import moment from "moment";
import { NGROK } from "../APIs";
import { myLocalStorage } from "../components/StorageHelper";
import { getClientId } from "../services/Auth";
import jwtDecode from "jwt-decode";


export const refreshAccessToken = async () => {
  const axiosInstance = axios.create();
  const ngrokWithoutProtocol = NGROK.slice(8);
  const realm = myLocalStorage.getItem("realm");
  const refresh_token = myLocalStorage.getItem("refresh_token");

  const url = `https://auth-${ngrokWithoutProtocol}/realms/${realm}/protocol/openid-connect/token`;

  const client_id = getClientId(realm);

  try {
    let body = {
      grant_type: "refresh_token",
      client_id,
      refresh_token,
    };
    body = qs.stringify(body);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    };

    const { data } = await axiosInstance.request(config);

    return {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
    };
  } catch (e) {
    console.error("Error during sign-in:", e);
    throw e;
  }
};

export const decodeKeycloakToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    console.error("Error decoding Keycloak token:", error);
    return null;
  }
};

export const sendTokenToBackend = async (
  realm,
  email,
  role,
  token,
  expirationDate
) => {
  const axiosInstance = axios.create();
  try {
    await axiosInstance.post(`${NGROK}/api/auth/authenticate`, {
      realm,
      email,
      role,
      token,
      expirationDate,
    });
  } catch (error) {
    console.error(error);
  }
};

export const checkIfTokenExpired = (token) => {
  if (!token) return false;

  const { exp: refreshTokenExprireTime } = decodeKeycloakToken(token);
  const expMoment = moment.unix(refreshTokenExprireTime);

  const isRefreshTokenExpired = expMoment.isBefore(moment());
  return isRefreshTokenExpired;
};
