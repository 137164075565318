import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Box,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

import { TabContext, TabPanel } from "@mui/lab";
import TopResources from "./TopResources";
import TopUsers from "./TopUsers";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const TopComputer = ({ data }) => {
  const [value, setValue] = useState("users");
  const [auditData, setAuditData] = useState(null);
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getComputerPlotData = (data) => {
    if (data) {
      const computers = data?.map((name) => {
        if (name?.computerName?.length > 0) return name.computerName;
        else return "default";
      });

      const computerGroup = computers.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});
      const sortedData = Object.entries(computerGroup)
        .sort((a, b) => b[1] - a[1])
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      const series = [
        {
          name: "",
          data: Object.values(sortedData),
        },
      ];

      const options = {
        chart: {
          type: "bar",
          stacked: true,

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: Object.keys(sortedData),
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value + " times";
            },
          },
        },
      };
      setAuditData(data);
      setSeries(series);
      setOptions(options);
      setLoading(false);
    }
  };
  useEffect(() => {
    getComputerPlotData(data);
  }, [data]);

  return (
    <>
      <TabContext value={value}>
        <Card>
          <CardHeader
            title={
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="users" label="Top Users" />

                <Tab value="resources" label="Top Resources" />
                <Tab value="computers" label="Top Computers" />
              </Tabs>
            }
          />
          <CardContent>
            <ChartWrapper>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                auditData !== null && (
                  <>
                    <TabPanel value="computers">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="bar"
                          height="100%"
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value="resources">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <TopResources data={data} />
                      </Box>
                    </TabPanel>
                    <TabPanel value="users">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <TopUsers data={data} />
                      </Box>
                    </TabPanel>
                  </>
                )
              )}
            </ChartWrapper>
          </CardContent>
        </Card>
      </TabContext>
    </>
  );
};

export default TopComputer;
