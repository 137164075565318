import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import {
  Grid,
  Divider as MuiDivider,
  CircularProgress,
  Autocomplete,
  TextField,
  Box,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red, yellow } from "@mui/material/colors";

import Stats from "./Stats";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import ResoruceType from "./ResoruceType";
import TimeBasedSeries from "./TimeBasedSeries";
import TopComputer from "./TopComputer";
import RecentActivity from "./RecentActivity";
import RecentLogin from "./RecentLogin";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs from "dayjs";

const Divider = styled(MuiDivider)(spacing);

function Default() {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);

  const [identitiesCount, setIdentitiesCount] = useState(0);
  const [identitiesCountAtMonthStart, setIdentitiesCountAtMonthStart] =
    useState(0);
  const [resourcesCount, setResourcesCount] = useState(0);
  const [resourcesCountAtMonthStart, setResourcesCountAtMonthStart] =
    useState(0);
  const [sessionsCount, setSessionsCount] = useState(0);
  const [suspiciousAccountsCount, setSuspiciousAccountsCount] = useState(0);
  const [
    suspiciousAccountsCountAtMonthStart,
    setSuspiciousAccountsCountAtMonthStart,
  ] = useState(0);
  const [identitiesPercentegDifferences, setIdentitiesPercentegDifferences] =
    useState(0);
  const [resourcesPercentegDifferences, setResourcesPercentegDifferences] =
    useState(0);
  const [activeSessionsPercentegDifferences] = useState(0);
  const [
    suspiciousAccountsPercentegDifferences,
    setSuspiciousAccountsPercentegDifferences,
  ] = useState(0);
  const [auditData, setAuditData] = useState([]);

  const [tenantList, setTenantList] = useState([]);
  const [filterPagination, setFilterPagination] = useState("lw");
  const [customMode, setCustomMode] = useState(false);
  const [startEndDate, setStartEndDate] = useState([dayjs(), dayjs()]);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const calculatePercentageDifference = (currentCount, atMonthStartCount) => {
    const smallerNumber = Math.min(currentCount, atMonthStartCount);
    const biggerNumber = Math.max(currentCount, atMonthStartCount);

    if (currentCount === 0 && atMonthStartCount !== 0) return "-100";
    else if (currentCount !== 0 && atMonthStartCount === 0) return "100";
    else if (currentCount === 0 && atMonthStartCount === 0) return "0.00";
    else {
      const differenceInPercentage =
        ((biggerNumber - smallerNumber) / smallerNumber) * 100;

      return currentCount > atMonthStartCount ||
        currentCount === atMonthStartCount
        ? `${differenceInPercentage.toFixed(2)}`
        : `-${differenceInPercentage.toFixed(2)}`;
    }
  };

  const fetchStatisticsData = async (endpoint, setter) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/statistics${endpoint}?tenantName=${selectedTenantName}`
      );

      setter(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let currentDate = new Date();
    setStartEndDate([dayjs(currentDate), dayjs(currentDate)]);
  }, []);

  useEffect(() => {
    if (tenantList?.length === 1)
      setSelectedTenantName(tenantList[0].tenantName);
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const fetchIdentetiesCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenantName}/computer-users/count`
      );
      setIdentitiesCount(response.data);
    } catch (error) {
      console.log(error, "error fetchIdentetiesCount");
    }
  }, [selectedTenantName]);

  const fetAuditData = useCallback(async () => {
    try {
      // const response = await axios.get(`${NGROK}/api/audit-logs`);
      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&timingMode=${filterPagination}`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&searchParams=${selectedTenantName}`;
      }
      const response = await axios.get(url);
      let auditvalue = [];
      const modifiedData = response.data.map((item) => {
        return {
          ...item,
          time:
            item.timestamp !== undefined && item.timestamp !== null
              ? item.timestamp
              : item._time,
        };
      });
      auditvalue.push([...modifiedData]);
      setAuditData(modifiedData);
    } catch (e) {
      console.log("defauklt call error", e);
    }
  }, [
    filterPagination,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
  ]);

  const fetchResourcesCount = useCallback(async () => {
    try {
      const response = await axios.all([
        axios.get(`${NGROK}/api/${selectedTenantName}/groups/count`),
        axios.get(`${NGROK}/api/${selectedTenantName}/apps/count`),
        axios.get(`${NGROK}/api/${selectedTenantName}/folders/count`),
      ]);
      const result = response.reduce((acc, current) => {
        return acc + current.data;
      }, 0);
      setResourcesCount(result);
    } catch (error) {
      console.log(error, "error fetchResourcesCount");
    }
  }, [selectedTenantName]);

  const fetchSessionsCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/sessions/${selectedTenantName}/count`
      );
      setSessionsCount(response.data);
    } catch (error) {
      console.log(error, "error fetchSessionsCount");
    }
  }, [selectedTenantName]);

  const fetchSuspiciousAccountsCount = useCallback(() => {
    let suspiciousAccounts;
    try {
      if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
        axios
          .get(`${NGROK}/api/computer-users/tenant?name=${selectedTenantName}`)
          .then((res) => {
            if (res.data) {
              suspiciousAccounts = res.data.filter(
                (account) => account.suspiciousAccount
              );
            }
          });
      } else if (userData.role === "TENANT_USER") {
        axios
          .get(`${NGROK}/api/computer-users/tenant?name=${selectedTenantName}`)
          .then((res) => {
            if (res.data) {
              suspiciousAccounts = res.data.filter(
                (account) => account.suspiciousAccount
              );
            }
          });
      }
      if (suspiciousAccounts === undefined) suspiciousAccounts = [];
      setSuspiciousAccountsCount(suspiciousAccounts.length);
    } catch (error) {
      console.log(error, "error setSuspiciousAccountsCount");
    }
  }, [selectedTenantName, userData.role]);

  const handleTenantChange = (value) => {
    if (!value) {
      setIdentitiesCount(0);
      setResourcesCount(0);
      setSessionsCount(0);
      setSuspiciousAccountsCount(0);
    }
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
    }
  };

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      identitiesCount,
      identitiesCountAtMonthStart
    );
    setIdentitiesPercentegDifferences(percentegDifferences);
  }, [identitiesCount, identitiesCountAtMonthStart]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      resourcesCount,
      resourcesCountAtMonthStart
    );
    setResourcesPercentegDifferences(percentegDifferences);
  }, [resourcesCount, resourcesCountAtMonthStart]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      suspiciousAccountsCount,
      suspiciousAccountsCountAtMonthStart
    );
    setSuspiciousAccountsPercentegDifferences(percentegDifferences);
  }, [suspiciousAccountsCount, suspiciousAccountsCountAtMonthStart]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchIdentetiesCount();
      fetchResourcesCount();
      fetchSessionsCount();
      fetchSuspiciousAccountsCount();
    }, 5000);
    return () => clearInterval(interval);
  }, [
    fetchIdentetiesCount,
    fetchResourcesCount,
    fetchSessionsCount,
    fetchSuspiciousAccountsCount,
  ]);

  useEffect(() => {
    fetchIdentetiesCount();
    fetchResourcesCount();
    fetchSessionsCount();
    fetchSuspiciousAccountsCount();
    fetchStatisticsData(
      "/computers-users/count",
      setIdentitiesCountAtMonthStart
    );
    fetchStatisticsData("/resources/count", setResourcesCountAtMonthStart);
    fetchStatisticsData(
      "/computers-users/suspicious/count",
      setSuspiciousAccountsCountAtMonthStart
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantName]);

  useEffect(() => {
    fetAuditData();
  }, [fetAuditData, filterPagination]);

  const handleChangeDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };
  const dataPagination = [
    { label: "Last 24 hours", value: "24Hrs" },
    { label: "Last 7 days ", value: "lw" },
    { label: "Last 30 days", value: "lm" },
    { label: "Last 90 days", value: "l3m" },
    { label: "Custom Range", value: "customDate" },
  ];
  const handleFilterPagination = (val) => {
    setCustomMode(false);
    if (val !== null) {
      let value = dataPagination
        .filter((data) => data.label === val)
        .map((val) => val.value);
      if (value[0] === "customDate") {
        setCustomMode(true);
      } else {
        setFilterPagination(value[0]);
      }
    } else {
      setFilterPagination("lw");
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          {tenantList?.length > 0 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedTenantName ? selectedTenantName : " "}
              options={tenantList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => {
                handleTenantChange(value);
              }}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataPagination.map((value) => value.label)}
            value={
              dataPagination
                .filter((data) => data.value === filterPagination)
                .map((val) => val.label)[0]
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Time Duration" />
            )}
            onChange={(e, value) => {
              handleFilterPagination(value);
            }}
          />
          {customMode ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Start date"
                  endText="End date"
                  value={startEndDate}
                  onChange={handleChangeDateRange}
                  renderInput={(startProps, endProps) => (
                    <Stack direction={"row"} spacing={3}>
                      <TextField {...startProps} />
                      <TextField {...endProps} />
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </>
          ) : null}
        </Box>
      </Box>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Identities"
            amount={
              identitiesCount !== undefined ? (
                identitiesCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${identitiesPercentegDifferences}%`}
            percentagecolor={
              identitiesPercentegDifferences > 0
                ? green[500]
                : identitiesPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Resources"
            amount={
              resourcesCount !== undefined ? (
                resourcesCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${resourcesPercentegDifferences}%`}
            percentagecolor={
              resourcesPercentegDifferences > 0
                ? green[500]
                : resourcesPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Active Sessions"
            amount={
              sessionsCount !== undefined ? sessionsCount : <CircularProgress />
            }
            chip="Today"
            percentagetext={`${activeSessionsPercentegDifferences}%`}
            percentagecolor={
              activeSessionsPercentegDifferences > 0
                ? green[500]
                : activeSessionsPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Suspicious accounts"
            amount={
              suspiciousAccountsCount !== undefined ? (
                suspiciousAccountsCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${suspiciousAccountsPercentegDifferences}%`}
            percentagecolor={
              suspiciousAccountsPercentegDifferences > 0
                ? green[500]
                : suspiciousAccountsPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={6} md={6}>
          <ResoruceType data={auditData} />
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <TimeBasedSeries
            data={auditData}
            mode={filterPagination}
            customDate={startEndDate}
            customMode={customMode}
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6} md={6}>
              <Grid container gap={2}>
                <Grid item xs={12} lg={12} md={12}>
                  <TopComputer data={auditData} />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <RecentLogin data={auditData} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <RecentActivity data={auditData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
